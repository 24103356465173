import { render, staticRenderFns } from "./InviteFarmersDialog.vue?vue&type=template&id=f5d6e2b8&scoped=true&"
import script from "./InviteFarmersDialog.vue?vue&type=script&lang=js&"
export * from "./InviteFarmersDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5d6e2b8",
  null
  
)

export default component.exports