<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="primary"
        v-on="on"
        v-bind="attrs"
        style="text-transform: none"
      >
        farmersを招待
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title> farmersを招待 </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            label="招待メールアドレス"
            :rules="emailRules"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false"> 閉じる </v-btn>
        <v-btn
          color="primary"
          class="text--white"
          @click="onClickInvite"
          :disabled="isLoading"
        >
          <span v-if="!isLoading"> 送信 </span>
          <span v-else>
            <v-progress-circular indeterminate></v-progress-circular>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'InviteFarmersDialog',
  data() {
    return {
      dialog: false,
      email: '',
      valid: false,
      isLoading: false,
      emailRules: [
        (v) => !!v || 'E-mail が 必要です',
        (v) => /.+@.+\..+/.test(v) || 'E-mail の形式が正しくありません',
      ],
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onClickInvite() {
      this.isLoading = true;
      const tmpPassword = 'Pass' + Math.random().toString(32).substring(2);
      const userData = {
        username: `招待中のユーザー(${this.email})`,
        email: this.email,
        password: tmpPassword,
      };
      if (this.validate()) {
        axios
          .post('/api/masters/invite_farmer/', userData)
          .then(() => {
            Swal.fire({
              icon: 'info',
              title: '招待を送信しました',
              text: `${this.email} 宛に招待メールを送信しました。`,
              showConfirmButton: true,
              showCloseButton: false,
            }).then(() => {
              this.$emit('reload');
              this.email = '';
              this.dialog = false;
            });
          })
          .catch((err) => {
            if (err.response.data.email) {
              Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: `${this.email} は、既に登録済みのメールアドレスです。`,
                showConfirmButton: true,
                showCloseButton: false,
              });
            } else {
              const errorMessage = err.response?.data?.detail ? err.response.data.detail : 'サーバーエラーです'
              Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: errorMessage,
                showConfirmButton: true,
                showCloseButton: false,
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
<style scoped></style>
