<template>
  <v-dialog v-model="dialog" persistent max-width="640px">
    <v-card>
      <v-container>
        <v-row>
          <v-col :cols="chartCols">
            <v-card-title class="justify-center">
              {{ title }}
            </v-card-title>
            <v-card-subtitle class="justify-center">
              {{ subTitle }}
            </v-card-subtitle>
            <LineChart
              :propData="chartData"
              :labels="chartLabels"
              :height="320"
              @onChartClick="onChartClick"
            ></LineChart>
            <v-card-text>
              <div class="pb-0">
                平均値：栽培期間中の NDVI 実績値の平均 &nbsp; 平年値：過去分の NDVI
                実績値の平均
              </div>
            </v-card-text>
            <v-card-text>
              栽培履歴
              <v-list>
                <template v-for="crop in cropHistory">
                  <v-list-item :key="crop.id">
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        作物：{{ crop.cropName }} &nbsp; 定植日：{{
                          crop.plant_date
                        }}
                        &nbsp; 収穫日：{{ crop.harvest_date }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
          </v-col>
          <v-col v-if="imagePath" sm="4">
            <br>
            <br>
            <br>
            <v-img
              :src="imagePath"
              width="320px"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="closeModal"> 閉じる </v-btn>
        <v-btn color="primary" primary @click="onClickCSV">
          CSVダウンロード
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LineChart from "@/components/comps/LineChart";

/// UTF8用BOM
const UTF8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

// 栽培情報情報登録ダイアログ
export default {
  name: "ChartDialog",
  components: {
    LineChart,
  },
  props: {
    title: String(),
    subTitle: String(),
    plantInfo: Object(),
    chartData: Array(),
    chartLabels: Array(),
    cropHistory: Array(),
  },
  mounted() {},
  data() {
    return {
      dialog: false,
      targetDate: "",
      selectPos: ""
    };
  },
  computed: {
    imagePath () {
      if (!this.targetDate){
        return '';
      }
      return `/static/sentinel_ndvi/user_${this.plantInfo.user_id}/field_${this.plantInfo.field.id}/plant_${this.plantInfo.id}/${this.targetDate.toISOString().substr(0, 10)}.png`;
    },
    chartCols () {
      if (!this.targetDate){
        return '12';
      }
      return '8'
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    formReset() {
      return this.$refs.form.reset();
    },
    showModal() {
      this.dialog = true;
    },
    closeModal() {
      this.selectPos = "";
      this.targetDate = "";
      this.$emit("close");
      this.dialog = false;
    },
    onClickCSV() {
      const delta_date =
        new Date(this.plantInfo.harvest_date) -
        new Date(this.plantInfo.plant_date);
      const terms = delta_date / 86400000;
      const records = [];
      const ndviAvgList = this.chartData[0].data;
      const areaGroupAvgList = this.chartData[1].data;
      const ndviList = this.chartData[2].data;
      for (let elapse_date = 0; elapse_date < terms; elapse_date++) {
        const ndviAvg = ndviAvgList[elapse_date];
        const areaGroupAvg = areaGroupAvgList[elapse_date];
        const ndvi = ndviList[elapse_date];
        records.push(
          `${elapse_date + 1}, ${ndviAvg}, ${areaGroupAvg}, ${ndvi}`
        );
      }

      // ヘッダー生成
      const header = "経過日数,平年値,平均値,ndvi\r\n";

      // Blobオブジェクト生成
      const csvBlob = new Blob([UTF8_BOM, header, records.join("\r\n")], {
        type: "text/csv;charset=utf-8;",
      });
      this.downloadBlob(csvBlob, "csv");
    },

    downloadBlob(blob, ext) {
      const downloadUrl = window.URL.createObjectURL(blob);

      // ダウンロードリンク生成
      const link = document.createElement("a");
      link.href = downloadUrl;

      // ファイル名
      link.download = `${this.title}` + "." + ext;

      // ダウンロード実行
      document.body.appendChild(link);
      link.click();

      // ダウンロードリンク破棄
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    },
    
    onChartClick (event, tooltipItems) {
      if (!tooltipItems[0]) {
        return;
      }
      const chart = tooltipItems[0]._chart;
      const chartIndex = chart.getElementAtEvent(event);
      if (chartIndex.length == 0 || chartIndex[0]._datasetIndex != 2) {
        return;
      }
      if (this.selectPos == chartIndex[0]._index) {
        this.selectPos = "";
        this.targetDate = "";
        return;
      }
      this.selectPos = chartIndex[0]._index;
      this.targetDate = new Date(this.plantInfo.plant_date);
      this.targetDate.setDate(this.targetDate.getDate() + this.selectPos);
    }
  },
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.v-card__subtitle {
  display: flex;
}
</style>
