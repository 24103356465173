<template>
  <v-container fluid>
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
          トップページ
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          データ取得日: {{ today }}
        </v-card-subtitle>
      </v-card-text>
    </v-card>
    <v-row class="my-3">
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectGroupYear"
              :items="groupYearItemList"
              :rules="[(v) => !!v || '年度を設定してください']"
              label="年度"
              v-on:change="onSelectGroupYear"
            ></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectCrops"
              :items="cropList"
              :rules="[(v) => !!v || '作物を選択してください']"
              label="作物"
              v-on:change="onSelectCrop"
              multiple
            ></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-text class="d-flex justify-center flex-column">
            <v-card-title class="d-flex justify-center pa-0">
              生産者数 : {{ countUser }}
              <v-spacer></v-spacer>
              合計栽培面積： {{ convertDisplayArea(totalAreaSpace) }} a
            </v-card-title>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="plantinfos"
      :items-per-page="10"
      :item-key="id"
      :search="search"
      class="plantinfo"
    >
      <template v-slot:[`item.is_stop`]="{ item }">
        <div v-show="item.finish_date">
          <span v-if="item.is_stop" class="red--text">栽培中断</span>
          <span v-else class="green--text">栽培完了</span>
          <br>
          <span>{{ displayDate(item.finish_date)}}</span>
          <br>
          <span v-if="item.stop_memo">{{item.stop_memo}}</span>
          <br>
        </div>
      </template>
      <template v-slot:[`item.plant_date`]="{ item }">
        {{ displayDate(item.plant_date) }}
      </template>
      <template v-slot:[`item.harvest_date`]="{ item }">
        {{ displayDate(item.harvest_date) }}
      </template>
      <template v-slot:[`item.alert_elapse_date`]="{ item }">
        <span v-show="item.alert_status != 0">{{ alert_latest_elapse_date(item) }}</span>
      </template>
      <template v-slot:[`item.alert_status`]="{ item }">
        <v-chip :color="getColor(item.alert_status)" dark>
          <v-icon>{{ getIcon(item.alert_status) }}</v-icon>
        </v-chip>
      </template>
      <template v-slot:[`item.alert_memo`]="{ item }">
        <v-btn icon>
          <v-icon @click.stop="onViewAlertMemo(item)"
            >mdi-note-check</v-icon
          >
        </v-btn>
      </template>
      <template v-slot:[`item.is_masters_check`]="{ item }">
        <v-chip :color="getColor(item.is_masters_check)" dark>
          <v-avatar>
            <v-icon
              @click.stop="onCheckAlert(item)"
              :disabled="item.is_masters_check === false"
              >{{ getIcon(item.is_masters_check) }}</v-icon
            >
          </v-avatar>
        </v-chip>
      </template>
      <template v-slot:[`item.polygon.area_space`]="{ item }">
        {{ convertDisplayArea(item.polygon.area_space) }}
      </template>
      <template v-slot:[`item.teaching_history`]="{ item }">
        <v-btn icon>
          <v-icon @click.stop="onViewTeachingHistory(item)"
            >mdi-application-edit</v-icon
          >
        </v-btn>
      </template>
      <template v-slot:[`item.field`]="{ item }">
        <v-btn icon>
          <v-icon @click.stop="flyTo(item)">mdi-map-marker</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row dense class="mx-auto">
      <v-col sm="12" md="12" lg="12">
        <div class="viewer">
          <vc-viewer
            @ready="ready"
            :infoBox="false"
            :selectionIndicator="false"
          >
            <vc-layer-imagery>
              <vc-provider-imagery-arcgis-mapserver :url="url" />
            </vc-layer-imagery>
            <vc-entity
              v-for="plantinfo in displayPlantinfos"
              :key="plantinfo.id"
              @click="onPolygonClick(plantinfo)"
            >
              <vc-graphics-polygon
                :hierarchy="convertHierarchy(plantinfo)"
                :material="getMaterial(plantinfo)"
                :extrudedHeight="0.0"
                :closeTop="false"
                :closeBottom="false"
                :ref="polygonRef(plantinfo)"
                :outline="true"
                :outlineColor="getOutLineMaterial(plantinfo)"
                :outlineWidth="5"
              ></vc-graphics-polygon>
            </vc-entity>
          </vc-viewer>
        </div>
      </v-col>
    </v-row>
    <ChartDialog
      ref="chartDialog"
      :chartData="chartData"
      :chartLabels="chartLabels"
      :plantInfo="plantInfo"
      :title="displayChartTitle"
      :subTitle="displayChartSubTitle"
      :cropHistory="cropHistory"
      @close="onChartDialogClose"
    >
    </ChartDialog>
    <LoadingDialog ref="loadingDialog"> </LoadingDialog>
    <AlertConfirmListDialog ref="alertConfirmListDialog" :plantInfoId="plantInfoId" @reload="reload"></AlertConfirmListDialog>
    <CheckAlertDialog
      ref="checkAlertDialog"
      :item="plantInfoId"
      :username="manager"
      :isAlert="true"
      @reload="reload"
    ></CheckAlertDialog>
    <TeachingHistoryDialog
      ref="teachingHistoryDialog"
      :item="teachingHistory"
      :plantInfoId="plantInfoId"
      :username="manager"
      :userId="userId"
      @reload="reload"
    ></TeachingHistoryDialog>
  </v-container>
</template>

<script>
import ChartDialog from "@/components/comps/ChartDialog";
import LoadingDialog from "@/components/comps/LoadingDialog";
import AlertConfirmListDialog from "@/components/comps/AlertConfirmListDialog";
import CheckAlertDialog from "@/components/comps/CheckAlertDialog";
import TeachingHistoryDialog from "@/components/comps/TeachingHistoryDialog";
import AuthUtils from "@/utils/AuthUtils";
import router from "../../router";
import axios from "axios";
import moment from "moment";

export default {
  name: "Top",
  components: {
    ChartDialog,
    LoadingDialog,
    AlertConfirmListDialog,
    CheckAlertDialog,
    TeachingHistoryDialog,
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
      Cesium: {},
      viewer: {},
      camera: {},
      groupYearItemList: [],
      selectGroupYear: [],
      cropList: [],
      selectCrops: [],
      zipcode: "",
      countUser: 0,
      totalAreaSpace: 0,
      headers: [
        { text: "", value: "is_stop", sortable: false },
        { text: "作物", value: "plant_master.crop.name" },
        { text: "生産者", value: "polygon.user.username" },
        { text: "ほ場", value: "field.name" },
        { text: "面積(a)", value: "polygon.area_space" },
        { text: "定植日", value: "plant_date" },
        { text: "収穫(予定)日", value: "harvest_date" },
        { text: "アラート発生経過日", value: "alert_elapse_date" },
        { text: "生産者アラート確認状況", value: "alert_status" },
        { text: "生産者アラート確認履歴", value: "alert_memo" },
        { text: "アラート確認", value: "is_masters_check" },
        { text: "指導履歴", value: "teaching_history", sortable: false },
        { text: "", value: "field", sortable: false },
      ],
      alert_status: "",
      plantinfos: [],
      selectPolygon: "",
      plantInfo: {},
      plantInfoId: null,
      manager: "",
      userId: null,
      teachingHistory: [],
      ndviData: [],
      ndviAvg: [],
      ndviAreaGroupAvg: [],
      ndviAvgComparison: [],
      cropHistory: [],
      coopIdList: [],
      coopPlantMasterList: [],
      allCoopPlantMasterList: [],
      id: "",
      index: -1,
      search: "",
      material: [0, 255, 0, 0],
      alertMaterial: [255, 0, 0, 0],
      outLineMaterial: [0, 255, 0, 0],
      outLineAlertMaterial: [255, 0, 0, 0],
    };
  },
  methods: {
    ready(cesiumInstance) {
      const { Cesium, viewer } = cesiumInstance;
      viewer.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );
      this.Cesium = Cesium;
      this.viewer = viewer;
    },
    reload() {
      this.checkLoggedIn();
      const current_user = AuthUtils.currentUser(this.$session);
      this.userId = current_user.id;
      this.$refs['loadingDialog'].showModal();
      Promise.all([
        axios
          .get(`/api/masters/group_years/?user_id=${current_user.id}`)
          .then((res) => {
            const groupByGrouopYear = this.groupBy(res.data, "date");
            this.groupYearItemList = Object.keys(groupByGrouopYear)
              .map(function (k) {
                return {
                  text: new Date(k).toISOString().substr(0, 4),
                  value: groupByGrouopYear[k],
                };
              })
              .sort(function (a, b) {
                return b.text - a.text;
              });
            if (this.groupYearItemList[0]) {
              this.selectGroupYear = this.groupYearItemList[0].value;
              this.onSelectGroupYear();
              this.cropList.forEach((crops) => {
                this.selectCrops.push(crops.value);
              });
              this.onSelectCrop();
            }
          }),
        axios.get(`/api/coop_users/?masters=`).then((res) => {
          this.coopIdList = res.data.filter((coopuser) => {
            return coopuser.status === 1; // 連携済み
          });
        }),
        axios
          .get(`/api/coop_plant_masters/?masters_user_id=${current_user.id}`)
          .then((res) => {
            this.allCoopPlantMasterList = res.data.map(
              (allcoopplantmaster) => allcoopplantmaster.plant_master_id
            );
          }),
        axios.get(`/api/ndvi_avg/`).then((res) => {
          this.ndviAvgComparison = res.data;
        })
      ]).then(() => {
        // 平年値比較用
        this.ndviAvgComparison = this.ndviAvgComparison.filter(
          (ndviavgcomparison) =>
            this.allCoopPlantMasterList.indexOf(ndviavgcomparison.plant_master.id) != -1
        )
        this.ndviAvgComparison.forEach((ndviavgcomparison) => {
          let r = Math.floor(Math.random() * 255);
          let g = Math.floor(Math.random() * 255);
          let b = Math.floor(Math.random() * 255);
          let color = `rgb(${r},${g},${b})`;
          ndviavgcomparison.color = color
        })

        this.$refs['loadingDialog'].closeModal();
      })
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios
          .get("/api/current_user/")
          .then((res) => {
            this.$session.set("current_user", res.data);
          })
          .catch(() => {
            this.$session.destroy();
            router.push("/auth");
          });
      }
    },
    zoom() {
      if (Object.keys(this.viewer).length) {
        this.viewer.camera.flyTo({
          destination: this.Cesium.Cartesian3.fromDegrees(
            this.zoomX,
            this.zoomY,
            12000.0
          ),
        });
      } else {
        setTimeout(this.zoom, 100);
      }
    },
    onSelectGroupYear() {
      this.cropList = [];
      this.plantinfos = [];
      this.selectCrops = [];
      this.countUser = 0;
      this.totalAreaSpace = 0;
      const groupByCropList = this.groupBy(
        this.selectGroupYear.map((groupYear) => {
          return {
            key: groupYear.plant_master.crop.name,
            value: groupYear,
          };
        }),
        "key"
      );
      this.cropList = Object.keys(groupByCropList).map(function (k) {
        return { text: k, value: groupByCropList[k] };
      });
    },
    onSelectCrop() {
      this.plantinfos = [];
      const userIdList = [];
      let areaSpace = 0;
      this.selectCrops.forEach((crops) => {
        crops.map((crop) => {
          crop.value.plant_info_list.forEach((plant_info_list) => {
            if (plant_info_list.is_stop === false) {
              areaSpace += plant_info_list.polygon.area_space;
              userIdList.push(plant_info_list.user_id);
            }
            this.plantinfos.push(plant_info_list);
          });
        });
      });
      this.countUser = [...new Set(userIdList)].length;
      this.totalAreaSpace = areaSpace;

      if (this.plantinfos.length) {
        this.zipcode = this.plantinfos[0].field.user.zipcode;
        axios
          .get(`/api/geoapi_from_postal/?zipcode=${this.zipcode}`)
          .then((res) => {
            this.zoomX = res.data.x;
            this.zoomY = res.data.y;
            this.zoom();
          });
      }
    },
    flyTo(item) {
      this.viewer.camera.flyTo({
        destination: this.Cesium.Cartesian3.fromDegrees(
          item.polygon.poslist[0].lng,
          item.polygon.poslist[0].lat,
          1000.0
        ),
      });
    },
    convertHierarchy(plantinfo) {
      return plantinfo.polygon.poslist.map((pos) => {
        return { lng: pos.lng, lat: pos.lat, height: 0 };
      });
    },
    polygonRef(plantinfo) {
      return `polygon_${plantinfo.id}`;
    },
    onPolygonClick(plantinfo) {
      if (!plantinfo) {
        return;
      }
      const coopId = this.coopIdList.filter(
        (coopIdList) => coopIdList.farmers_user.id === plantinfo.user_id
      )[0].id;
      Promise.all([
        axios
          .get(
            `/api/ndvi_group_year_avg/?plant_master_id=${plantinfo.plant_master.id}&group_year_id=${plantinfo.group_year.id}`
          )
          .then((res) => {
            if (res.data[0]) {
              this.ndviAreaGroupAvg = res.data[0];
            }
          }),
        axios
          .get(`/api/ndvi_avg/?plant_master_id=${plantinfo.plant_master.id}`)
          .then((res) => {
            if (res.data[0]) {
              this.ndviAvg = res.data[0];
            }
          }),
        axios
          .get(`/api/ndvi/?plant_info_id=${plantinfo.id}&is_valid=True`)
          .then((res) => {
            this.ndviData = res.data;
            this.ndviData.sort((a, b) => a.elapse_date - b.elapse_date);
          }),
        axios.get(`/api/coop_plant_masters/?coop_id=${coopId}`).then((res) => {
          this.coopPlantMasterList = res.data.map(
            (coopplantmaster) => coopplantmaster.plant_master_id
          );
        }),
        axios
          .get(`/api/plant_info/?field_id=${plantinfo.field.id}`)
          .then((res) => {
            const plantinfos = res.data;
            plantinfos.sort(
              (a, b) => new Date(a.plant_date) - new Date(b.plant_date)
            );
            this.cropHistory = [];
            plantinfos.forEach((plantinfo) => {
              this.cropHistory.push({
                plant_date: this.displayDate(plantinfo.plant_date),
                harvest_date: this.displayDate(plantinfo.harvest_date),
                cropName: plantinfo.plant_master.crop.name,
                plant_master_id: plantinfo.plant_master.id,
              });
            });
          }),
      ]).then(() => {
        this.selectPolygon = plantinfo.polygon.id;
        this.plantInfo = plantinfo;
        this.cropHistory.filter(
          (cropHistory) =>
            this.coopPlantMasterList.indexOf(cropHistory.plant_master_id) != -1
        ); // 連携している plant_master のみ表示
        this.$refs["chartDialog"].showModal();
      });
    },
    onChartDialogClose() {
      this.selectPolygon = "";
      this.plantInfo = "";
    },
    onViewAlertMemo(plantinfo) {
      this.plantInfoId = plantinfo.id;
      this.$refs["alertConfirmListDialog"].showModal();
    },
    onCheckAlert(plantinfo) {
      const currentUser = this.$session.get('current_user');
      this.manager = currentUser.username;
      this.plantInfoId = plantinfo.id;
      this.$refs["checkAlertDialog"].showModal();
    },
    onViewTeachingHistory(plantinfo) {
      const currentUser = this.$session.get('current_user');
      this.manager = currentUser.username;
      this.plantInfoId = plantinfo.id;
      axios
        .get(`/api/masters/teaching_history/?plant_info_id=${plantinfo.id}`)
        .then((res) => {
          this.teachingHistory = res.data;
        });
      this.$refs["teachingHistoryDialog"].showModal();
    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    getPointColor(ndvi) {
      if (ndvi.status == -1) return "#FF00FF";
      if (ndvi.status == 1) return "#00FFFF";
      return "#00FF00";
    },
    displayDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    },
    convertDisplayArea(area_space) {
      // to a
      return Math.round((area_space / 100) * 10) / 10;
    },
    getColor(status) {
      return status != 0 ? "red" : "green";
    },
    getIcon(status) {
      return status != 0
        ? "mdi-alert-circle-outline"
        : "mdi-checkbox-marked-circle-outline";
    },
    getMaterial(plantinfo) {
      if (!plantinfo) return this.material;
      return plantinfo.is_masters_check != 0 && plantinfo.alert_status != 0
        ? this.alertMaterial
        : this.material;
    },
    getOutLineMaterial(plantinfo) {
      if (!plantinfo) return this.outLineMaterial;
      return plantinfo.is_masters_check != 0 && plantinfo.alert_status != 0
        ? this.outLineAlertMaterial
        : this.outLineMaterial;
    },
    alert_latest_elapse_date(item){
      const now = new Date();
      const plant_date = new Date(item.plant_date);
      plant_date.setDate(plant_date.getDate() + item.latest_alert_date)
      return Math.round((now - plant_date) / 86400000);
    }
  },
  computed: {
    today() {
      let now_date = moment();
      return now_date.format('YYYY/MM/DD');
    },
    displayPlantinfos() {
      if (!this.selectCrops) return [];
      this.plantinfos.forEach((plantinfo) => {
        plantinfo.polygon.poslist.sort((a, b) => a.sequence - b.sequence);
      });
      return this.plantinfos;
    },
    displayChartTitle() {
      if (!Object.keys(this.plantInfo).length) return "";
      return `${this.plantInfo.field.user.username}:${this.plantInfo.field.name}`;
    },
    displayChartSubTitle() {
      if (!Object.keys(this.plantInfo).length) return "";
      const plant_date = this.displayDate(this.plantInfo.plant_date);
      const harvest_date = this.displayDate(this.plantInfo.harvest_date);
      return `作物：${this.plantInfo.plant_master.crop.name}  定植日：${plant_date}  収穫日：${harvest_date}`;
    },
    chartLabels() {
      let elapse_date = [];
      if (!this.selectPolygon) {
        let NAelapse_date = [];
        let NYGAelapse_date = [];
        if (
          Object.keys(this.ndviAvg).length > 0 &&
          this.ndviAvg.ndvi_avg_value_list.length > 0
        ) {
          NAelapse_date = this.ndviAvg.ndvi_avg_value_list.map(
            (ndvi_avg_value) => ndvi_avg_value.elapse_date
          );
        }
        if (
          Object.keys(this.ndviAreaGroupAvg).length > 0 &&
          this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.length > 0
        ) {
          NYGAelapse_date =
            this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.map(
              (ndvi_avg_value) => ndvi_avg_value.elapse_date
            );
        }

        elapse_date =
          NAelapse_date.lengh > NYGAelapse_date
            ? NAelapse_date
            : NYGAelapse_date;
      } else {
        const delta_date =
          new Date(this.plantInfo.harvest_date) -
          new Date(this.plantInfo.plant_date);
        elapse_date = [...Array(delta_date / 86400000).keys()]
        elapse_date = elapse_date.map(elapse_date => {
          const e_date = new Date(this.plantInfo.plant_date);
          e_date.setDate(e_date.getDate() + elapse_date);
          return e_date.toISOString().substr(5,5).replaceAll("-","/");
        });
      }
      return elapse_date;
    },
    chartData() {
      const chartData = [];
      const ndviData = [];
      const ndviColor = [];
      let avgData = [];
      let avgAreaGroupData = [];
      let alertNdvi = null;
      let order = 3;

      const delta_date =
        new Date(this.plantInfo.harvest_date) -
        new Date(this.plantInfo.plant_date);
      const terms = delta_date / 86400000;
      for (let elapse_date = 0; elapse_date < terms; elapse_date++) {
        // ndvi
        const ndvi = this.ndviData.filter((ndvi) => {
          return ndvi.elapse_date == elapse_date;
        });
        if (ndvi.length > 0) {
          ndviData.push(ndvi[0].value);
          ndviColor.push(this.getPointColor(ndvi[0]));
          if (ndvi[0].alert_status != 0) {
            alertNdvi = ndvi[0];
          } else {
            alertNdvi = null;
          }
        } else {
          ndviData.push(null);
          ndviColor.push(null);
        }

        // 平年値
        const avg = this.ndviAvg.ndvi_avg_value_list.filter((avg) => {
          return avg.elapse_date == elapse_date;
        });

        if (avg.length > 0) {
          avgData.push(avg[0].value);
        } else {
          avgData.push(null);
        }

        // 平均値
        const group_year_avg =
          this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.filter(
            (group_year_avg) => {
              return group_year_avg.elapse_date == elapse_date;
            }
          );

        if (group_year_avg.length > 0) {
          avgAreaGroupData.push(group_year_avg[0].value);
        } else {
          avgAreaGroupData.push(null);
        }
      }

      // 平年値比較用 平年値がなかった場合に出力する
        if (
          Object.keys(this.ndviAvg).length === 0 ||
          this.ndviAvg.ndvi_avg_value_list.length === 0
        ) {
          this.ndviAvgComparison.forEach((ndviavgcomparison) => {
            let comparisonAvgData = [];
            for (let elapse_date = 0; elapse_date <= terms; elapse_date++) {
              let comparisonavg = ndviavgcomparison.ndvi_avg_value_list.filter((avg) => {
                return avg.elapse_date == elapse_date;
              });
              if (comparisonavg.length > 0) {
                comparisonAvgData.push(comparisonavg[0].value);
              } else {
                comparisonAvgData.push(null);
              }
            }
            order++;
            chartData.push({
              label: `比較用平年値(${ndviavgcomparison.plant_master.crop.name}_${ndviavgcomparison.name})`,
              data: comparisonAvgData,
              borderColor: ndviavgcomparison.color,
              pointBackgroundColor: ndviavgcomparison.color,
              showLine: true,
              lineTension: 0.0,
              spanGaps: true,
              datalabels: {
                display: false,
              },
              order: order,
            });
          });
        } else {
          chartData.push({
            label: "平年値",
            data: avgData,
            borderColor: "#FF8800",
            pointBackgroundColor: "#FF8800",
            showLine: true,
            lineTension: 0.0,
            spanGaps: true,
            datalabels: {
              display: false,
            },
            order: 3,
          });
        }

      if (alertNdvi) {
        ndviColor[alertNdvi.elapse_date] = "#FF0000";
      }

      chartData.push({
        label: "平均値",
        data: avgAreaGroupData,
        borderColor: "#00AA88",
        pointBackgroundColor: "#00AA88",
        showLine: true,
        lineTension: 0.0,
        spanGaps: true,
        datalabels: {
          display: false,
        },
        order: 2,
      });

      chartData.push({
        label: "NDVI",
        data: ndviData,
        borderColor: "#00FF00",
        pointBackgroundColor: ndviColor,
        pointBorderColor: ndviColor,
        showLine: false,
        datalabels: {
          display: false
        },
        order: 1,
      });
      return chartData;
    },
  },
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display: none;
}
.plantInfo.selected .v-card {
  background-color: #888800 !important;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
</style>
