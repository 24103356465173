import axios from 'axios';

export default class AuthUtils {
  static currentUser(session){
    const currentUser = session.get('current_user');
    if (!currentUser) return false;
    if (currentUser.is_account) {
      return currentUser.parent;
    }
    return currentUser;
  }

  static isLite(session){
    const currentUser = session.get('current_user');
    // TODO CONST
    return currentUser.paid_user_list.filter((paid_user) => {
      if (paid_user.prime_type != 1) return false;
      if (!paid_user.expire) return true;
      const now = new Date();
      const expire = new Date(paid_user.expire);
      if (expire < now  ) return false;

      return true;
    }).length > 0
  }

  static isBasic(session){
    const currentUser = session.get('current_user');
    // TODO CONST
    return currentUser.paid_user_list.filter((paid_user) => {
      if (paid_user.prime_type != 2) return false;
      if (!paid_user.expire) return true;
      const now = new Date();
      const expire = new Date(paid_user.expire);
      if (expire < now  ) return false;
      
      return true;
    }).length > 0
  }

  static isUser(session) {
    const currentUser = session.get('current_user');
    if (!currentUser) return false;
    return !currentUser.is_account;
  }

  static isPermission(session){
    // TODO FIX CONSTRAINT
    const ADMIN_ACCOUNT_TYPE = 1;
    const currentUser = session.get('current_user');
    if (!currentUser) return false;
    return !currentUser.is_account || currentUser.account_type.id == ADMIN_ACCOUNT_TYPE;
  }

  static checkAccountPermission(session, router){
    if (!AuthUtils.isPermission(session)) {
      router.push("/");
    }
  }

  static checkExpire(expire) {
    if (!expire) return true;
    const now = new Date();
    const expireDate = new Date(expire);
    return expireDate - now > 0; 
  }

  static checkLoggedIn(session, router) {
    session.start();
    if (!session.has("token")) {
      session.destroy();
      router.push("/auth");
    } else {
      axios.defaults.headers.common[
        "Authorization"
      ] = `JWT ${session.get("token")}`;
      axios.get('/api/current_user/').then(res => {
        session.set('current_user',res.data);
      }).catch(()=>{
        session.destroy();
        router.push("/auth");
      });
    }
  }
}

