<template>
  <v-container >
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
            >{{displayText}}</v-card-title
          >        
          <div class="text-center">
            <v-btn
              class="primary" 
              v-on:click="submit">ログイン画面へ</v-btn>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import router from "../../router";
import axios from 'axios';

// ユーザー登録
export default {
  name: "VerifyEmail",
  components: {
  },
  mounted() {
    // tokenのvalidチェック
    // $route.params.token
    axios.get(`/api/verify_email/${this.$route.params.token}/`).then(res => {
      this.status = res.status;
      this.user_id = res.data?.user_id;
    }).catch((e) => {
      this.status = e.response.status;
    });
  },
  data() {
    return {
      message: {
        valid: "Emailの確認が完了しました。契約プランの料金を指定口座へお振り込みください。",
        invalid: "無効なURLです。",
        expired: "URLの有効期限が切れています。",
      },
      status: '',
      valid: false
    }
  },
  computed: {
    displayText () {
      if (this.status == 200) return this.message.valid;
      if (this.status == 400) return this.message.invalid;
      if (this.status == 401) return this.message.expired;
      return "";
    },
    displayAnnotation () {
      if (this.status == 200) return this.annotation.valid;
      if (this.status == 400) return this.annotation.invalid;
      if (this.status == 401) return this.annotation.expired;
      return "";
    },
    isSuccess () {
      return this.status == 200 && this.user_id;
    }
  },
  methods: {
    submit() {
      router.push("/auth");
    },
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hidden {
  display: none;
}
</style>
