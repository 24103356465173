<template>
  <v-container>
    <v-card
      class="d-flex justify-center flex-column my-2 gray"
      v-for="currentPaidUser in currentPaidUserList"
      :key="currentPaidUser.id"
    >
      <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
        契約プラン: {{ getPrimeTypeName(currentPaidUser.prime_type) }}
        <br />有効期限:
        {{ getExpire(currentPaidUser.expire) }}
      </v-card-title>

      <v-card-subtitle class="text-center">
        有効期限が切れる前に指定口座にお振り込みください。
        <br />プランの変更や決済についてのご質問は、お問い合わせにてご連絡ください。
      </v-card-subtitle>
    </v-card>

    <v-card class="d-flex justify-center flex-column my-2 gray" v-if="currentPaidUserList.length === 0">
      <v-card-text class="text-center">
        契約プランを選び、指定口座にお振り込みください。
        <br />プランや決済についてのご質問は、お問い合わせにてご連絡ください。
      </v-card-text>
    </v-card>

    <v-card-title class="justify-center"> プラン一覧 </v-card-title>
    <v-data-table class="whitespace"
      :headers="primeTypeListHeaders"
      :items="primeTypeList"
      :items-per-page="10"
      item-key="id"
    >
    <template v-slot:[`item.price`]="{ item }">
      {{ calcPrice(item.price) }}
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import router from "../../router";
import axios from "axios";

// 決済
export default {
  name: "Settlement",
  components: {},
  mounted() {
    this.reload();
  },
  data() {
    return {
      loading: false,
      primeTypeList: [],
      primeTypeListHeaders: [
        { text: "プラン名", value: "name" },
        { text: "プラン内容", value: "contents" },
        { text: "金額(税込)", value: "price" },
      ],
      valid: false,
    };
  },
  computed: {
    currentPaidUserList() {
      const current_user = this.$session.get("current_user");
      return current_user.paid_user_list;
    },
  },
  methods: {
    reload() {
      this.checkLoggedIn();
      axios.get("/api/prime_type_list/").then((res) => {
        this.primeTypeList = res.data.filter((primetype) => {
          return primetype.id >= 3 && primetype.id <= 10 // masters プランのみ表示
        });
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    formReset() {
      return this.$refs.form.reset();
    },
    getPrimeType(primeTypeId) {
      if (!this.primeTypeList) return;
      return this.primeTypeList.filter(
        (primeType) => primeType.id == primeTypeId
      )[0];
    },
    getPrimeTypeName(primeTypeId) {
      const primeType = this.getPrimeType(primeTypeId);
      if (primeType) return primeType.name;
      return "";
    },
    getExpire(expire) {
      if (expire) return new Date(expire).toISOString().substr(0, 10);
      return expire;
    },
    calcPrice(price) {
      return Math.round(price * 1.1).toLocaleString(); // 税込
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios
          .get("/api/current_user/")
          .then((res) => {
            this.$session.set("current_user", res.data);
          })
          .catch(() => {
            this.$session.destroy();
            router.push("/auth");
          });
      }
    },
  },
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hidden {
  display: none;
}
.whitespace {
  white-space: pre-wrap; 
}
</style>
