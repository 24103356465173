<template>
  <v-container>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">指導履歴</span>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="item"
          :sort-by="['updated_at']"
          :sort-desc="[true]"
          :items-per-page="10"
          :item-key="id"
          class="teaching-history"
        >
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ displayDate(item.updated_at) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon v-if="item.user.id===userId">
              <v-icon @click.stop="onClickUpdate(item)"
                >mdi-application-edit</v-icon
              >
            </v-btn>
            <v-btn icon v-if="item.user.id===userId">
              <v-icon @click.stop="onClickDelete(item)">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onClickAdd"> 追加 </v-btn>
          <v-btn color="red" @click="onClickClose"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CheckAlertDialog
      ref="checkAlertDialog"
      :item="plantInfoId"
      :username="username"
      :contents="contents"
      :teachingHistoryId="teachingHistoryId"
      :updated_at="updated_at"
    ></CheckAlertDialog>
  </v-container>
</template>

<script>
import CheckAlertDialog from "@/components/comps/CheckAlertDialog";
import axios from "axios";
import Swal from "sweetalert2";

// 指導履歴ダイアログ
export default {
  name: "TeachingHistoryDialog",
  components: {CheckAlertDialog},
  props: {
    item: Object(),
    plantInfoId: Number,
    username: String,
    userId: Number,
  },
  mounted() {
    this.loading = true;
  },
  data() {
    return {
      headers: [
        { text: "日付", value: "updated_at" },
        { text: "担当者", value: "manager" },
        { text: "指導内容", value: "comment" },
        { text: "", value: "actions", sortable: false },
      ],
      id: "",
      contents: "",
      teachingHistoryId: null,
      updated_at: "",
      dialog: false,
    };
  },
  methods: {
    displayDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    formReset() {
      return this.$refs.form.reset();
    },
    showModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    onClickClose() {
      this.dialog = false;
    },
    onClickAdd() {
      this.contents = null;
      this.$refs["checkAlertDialog"].showModal();
      this.dialog = false;
    },
    onClickUpdate(teachinghistory) {
      this.contents = teachinghistory.comment;
      this.teachingHistoryId = teachinghistory.id;
      this.updated_at = this.displayDate(teachinghistory.updated_at);
      this.$refs["checkAlertDialog"].showModal();
      this.dialog = false;
    },
    onClickDelete(teachinghistory) {
      Swal.fire({
        icon: "warning",
        title: "指導の削除",
        text: "この指導内容を削除しますか？",
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          axios
            .delete(`/api/masters/teaching_history/${teachinghistory.id}/`)
            .then(() => {
              this.loading = false;
              Swal.fire({
                icon: "success",
                title: "削除完了",
                text: "削除しました",
                showConfirmButton: false,
                showCloseButton: true,
                timer: 3000,
              });
            })
            .catch(() => {
              Swal.fire({
                icon: "warning",
                title: "Error",
                text: "サーバーエラーです。",
                showConfirmButton: true,
                showCloseButton: false,
                timer: 3000,
              });
            });
          this.dialog = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
