import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueCesium from 'vue-cesium'
import router from './router'
import store from './store'
import VueSession from 'vue-session'

Vue.config.productionTip = false
Vue.use(VueCesium, {
  // cesiumPath is path of Cesium.js', for example:
  // local Cesium Build package:
  // cesiumPath: /static/Cesium/Cesium.js
  // Personal online Cesium Build package：
  // cesiumPath: 'https://zouyaoji.top/vue-cesium/statics/Cesium/Cesium.js'
  // Personal online SuperMap Cesium Build package：
  // cesiumPath: 'https://zouyaoji.top/vue-cesium/statics/SuperMapCesium/Cesium.js'
  // Official Online Cesium Build package：
  cesiumPath: 'https://cesium.com/downloads/cesiumjs/releases/1.91/Build/Cesium/Cesium.js',
  // Cesium.Ion.defaultAccessToken
  accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhZGMzZmJmNi04NWRiLTQ3YTYtYWZmYy0xYzk1NzI2OWQxYjciLCJpZCI6Njg1NTYsImlhdCI6MTYzMjcwNzk2NH0.E3LKpAga2GM5qNumLHyOI-Oh_ChUYXs7HyFrmpWAbHk'
})
Vue.use(VueSession)
Vue.use(require('vue-shortkey'))

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created: () => (document.documentElement.setAttribute('lang', 'ja'))
}).$mount('#app')
