<template>
  <v-container >
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
          >アカウント一覧</v-card-title
        >
        <v-card-text>
          アカウント一覧の確認、編集を行えます。
        </v-card-text>
        <v-card-text>
          <AcountDialog ref="accountDialog" @reload="reload">
          </AcountDialog>
        </v-card-text>
        <v-list>
          <template v-for="(account, index) in accountList">
            <v-list-item
              :key="account.id"
            >
              <v-list-item-content class="text-h6">
                アカウント名 : {{ account.user.username }} &nbsp; メールアドレス : {{ account.user.email }}
              </v-list-item-content>
              <v-list-item-action>
                <v-icon @click.stop="onClickDeleteAccount(account)">mdi-delete</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < accountList.length - 1"
              :key="`account_list_div_${index}`"
            ></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AcountDialog from '@/components/comps/AcountDialog'
import AuthUtils from '@/utils/AuthUtils.js'
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

// 栽培情報
export default {
  name: "AccountList",
  components: {
    AcountDialog
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      accountList: [],
    };
  },
  computed: {
  },
  methods: {
    reload() {
      this.checkLoggedIn();
      AuthUtils.checkAccountPermission(this.$session, router);

      axios.get('/api/account_types/').then((res) => {
        this.accountTypeList = res.data;
      });

      axios.get('/api/accounts/').then((res) => {
        this.accountList = res.data;
      });

    },
    onClickDeleteAccount(account) {
      Swal.fire({
        icon: 'warning',
        title: 'アカウントの削除',
        text: 'このアカウントを削除しますか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/accounts/${account.id}`).then(() => {
            this.reload();
            Swal.fire({
              icon: 'success',
              title: '削除完了',
              text: '削除しました',
              showConfirmButton:false,
              showCloseButton:true
            })
          });
        }
      });
    },
    /// TODO to mixin
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
        this.$session.destroy();
          router.push("/auth");
        });
      }
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.v-list-item__action {
  margin-left: 16px;
}
</style>
