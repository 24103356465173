<template>
  <v-container fluid>
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
          指導履歴一覧
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
          ></v-text-field>
        </v-card-subtitle>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="teachingHistories"
      :sort-by="['updated_at']"
      :sort-desc="[true]"
      :items-per-page="10"
      :item-key="id"
      :search="search"
      class="teachingHistory"
    >
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ displayDate(item.updated_at) }}
      </template>
      <template v-slot:[`item.plant_info.plant_date`]="{ item }">
        {{ displayDate(item.plant_info.plant_date) }}
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
import router from "../../router";
import axios from "axios";
import AuthUtils from "@/utils/AuthUtils";
export default {
  name: "TeachingHistory",
  components: {
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      headers: [
        { text: "日付", value: "updated_at" },
        { text: "生産者", value: "plant_info.polygon.user.username" },
        { text: "作物", value: "plant_info.plant_master.crop.name" },
        { text: "ほ場", value: "plant_info.field.name" },
        { text: "定植日", value: "plant_info.plant_date" },
        { text: "担当者", value: "manager" },
        { text: "指導内容", value: "comment" },
      ],
      teachingHistories: [],
      id: '',
      search: '',
    };
  },
  methods: {
    reload() {
      this.checkLoggedIn();
      const current_user = AuthUtils.currentUser(this.$session);
      axios
        .get(`/api/masters/teaching_history/?user_id=${current_user.id}`)
        .then((res) => {
          this.teachingHistories = res.data;
        });
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios
          .get("/api/current_user/")
          .then((res) => {
            this.$session.set("current_user", res.data);
          })
          .catch(() => {
            this.$session.destroy();
            router.push("/auth");
          });
      }
    },
    displayDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    },
  },
  computed: {},
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display: none;
}
.plantInfo.selected .v-card {
  background-color: #888800 !important;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
.inquiry {
  white-space: pre-line;
}
</style>
