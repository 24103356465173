<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="text-h5" v-if="!isUpdate">{{isAlert ? 'アラートの確認' : '指導内容の追加'}}</span>
          <span class="text-h5" v-if="isUpdate">指導内容の編集</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="日付"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="dateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="manager" label="担当者"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="comment"
                  label="状況・対応内容等"
                  :rules="commentRules"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="onClickDelete"> 閉じる </v-btn>
          <v-btn v-if="!isUpdate" color="primary" dark @click="submit"> 確認 </v-btn>
          <v-btn v-if="isUpdate" color="primary" dark @click="update"> 更新 </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import AuthUtils from "@/utils/AuthUtils";

// アラート確認ダイアログ
export default {
  name: "CheckAlertDialog",
  components: {},
  props: {
    item: Number,
    username: String,
    isAlert: Boolean,
    contents: String,
    teachingHistoryId: Number,
    updated_at: String,
  },
  mounted() {
    this.loading = true;
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateRules: [(v) => !!v || "日付 が 必要です"],
      menu: false,
      manager: "",
      managerRules: [
        (v) =>
          String(v).length <= 256 || "担当者 は 256文字以内で入力してください",
      ],
      comment: "",
      commentRules: [(v) => !!v || "状況・対応内容等 が 必要です"],
      valid: false,
      dialog: false,
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.username) {
          this.manager = this.username;
        }
        if (this.contents) {
          this.comment = this.contents;
        }
        if (this.updated_at) {
          this.date = this.updated_at;
        }
      }
    }
  },
  computed: {
    isUpdate(){
      return this.contents != undefined;
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    formReset() {
      return this.$refs.form.reset();
    },
    showModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    submit() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        const updatedAt = new Date(this.date)
        Promise.all([
          axios
            .patch(`/api/plant_info/${this.item}/`, {
              is_masters_check: false,
            }),
          axios
            .post(`/api/masters/teaching_history/`, {
              user_id: current_user.id,
              plant_info_id: this.item,
              comment: this.comment,
              manager: this.manager,
              updated_at: updatedAt.toISOString(),
            })
            .then(() => {
              this.loading = false;
              this.formReset();
              this.dialog = false;
              const title = this.isAlert ? '確認済み' : '追加完了'
              const text = this.isAlert ? 'アラートを確認しました。' : '指導内容を追加しました。'
              Swal.fire({
                icon: "info",
                title: title,
                text: text,
                showConfirmButton: true,
                showCloseButton: false,
                timer: 3000,
              });
              this.$emit("reload");
            })
            .catch(() => {
              this.loading = false;
              Swal.fire({
                icon: "warning",
                title: "Error",
                text: "サーバーエラーです。",
                showConfirmButton: true,
                showCloseButton: false,
                timer: 3000,
              });
            })
        ])
      }
    },
    update(){
      if (this.validate()) {
        this.loading = true;
        const updatedAt = new Date(this.date)
        axios.patch(`/api/masters/teaching_history/${this.teachingHistoryId}/`, {
          comment: this.comment,
          manager: this.manager,
          updated_at: updatedAt.toISOString(),
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '更新完了',
            text: '指導内容の更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      this.dialog = false;
      this.manager = "";
      this.comment = "";
    },
  },
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
