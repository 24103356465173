<template>
  <v-container fluid>
    <div class="invitation_wrapper">
      <CooperationUsersDialog @reload="reload" />
      <InviteFarmersDialog @reload="reload" />
    </div>
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
          for farmers 連携
        </v-card-title>
        <v-card-subtitle class="d-flex justify-center">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
          ></v-text-field>
        </v-card-subtitle>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="coopUsers"
      :items-per-page="5"
      :item-key="id"
      :search="search"
      class="coopUser"
    >
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" :disabled="item.status !== 2">
              <v-icon>mdi-vector-link</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click.stop="onClickApprove(item)">
              <v-list-item-title>承認</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="onClickRefuse(item)">
              <v-list-item-title>拒否</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="item.coop_user?.is_valid_email === false"
              link
              @click="onClickResend(item)"
            >
              <v-list-item-title>招待の再送</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon>
          <v-icon @click.stop="onClickDelete(item)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import InviteFarmersDialog from '@/components/comps/InviteFarmersDialog';
import CooperationUsersDialog from '@/components/comps/CooperationUsersDialog';
import router from '../../router';
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  name: 'CoopUser',
  components: {
    InviteFarmersDialog,
    CooperationUsersDialog,
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      headers: [
        { text: '日時', value: 'formatted_updated_at' },
        { text: 'ユーザー名', value: 'username' },
        { text: '住所', value: 'address' },
        { text: 'ステータス', value: 'status_name' },
        { text: '', value: 'actions', sortable: false },
      ],
      coopUsers: [
        {
          updated_at: '2022/10/18 9:00:00',
          username: 'xx農園',
          address: '大分県竹田市',
          status: '連携済み',
        },
      ],
      id: '',
      index: -1,
      search: '',
    };
  },
  methods: {
    reload() {
      this.checkLoggedIn();
      axios.get('/api/masters/coop_users/').then((res) => {
        this.coopUsers = res.data.map((obj) => {
          // v-data-tableに渡すためにデータを整形
          const status_names = [
            '連携済み',
            '新しい申請',
            '申請中',
            '却下',
          ]
          var date = new Date(obj.updated_at);
          obj.formatted_updated_at = `${date.getFullYear()}/${
            date.getMonth() + 1
          }/${date.getDate()}`;
          obj.username = obj.farmers_user.username;
          obj.address = obj.farmers_user.address;
          obj.status_name = status_names[obj.status - 1]
          return obj;
        });
      });
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has('token')) {
        this.$session.destroy();
        router.push('/auth');
      } else {
        axios.defaults.headers.common[
          'Authorization'
        ] = `JWT ${this.$session.get('token')}`;
        axios
          .get('/api/current_user/')
          .then((res) => {
            this.$session.set('current_user', res.data);
          })
          .catch(() => {
            this.$session.destroy();
            router.push('/auth');
          });
      }
    },
    onClickDelete(item) {
      const alertMessage = item.farmers_user?.created_masters === this.$session.get('current_user').id
        ? 'このユーザーとの連携を削除すると連携先ユーザーはGrowth Watcherにアクセスできなくなります。'
        : '';
      Swal.fire({
        icon: 'warning',
        title: '連携生産者の解除',
        text: `${alertMessage}この生産者との連携を解除しますか？`,
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          axios
            .delete(`/api/coop_users/${item.id}/`)
            .then(() => {
              this.loading = false;
              this.reload();
              Swal.fire({
                icon: 'success',
                title: '削除完了',
                text: '削除しました',
                showConfirmButton: false,
                showCloseButton: true,
              });
            })
            .catch(() => {
              Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'サーバーエラーです。',
                showConfirmButton: true,
                showCloseButton: false,
                timer: 3000,
              });
            });
        }
      });
    },
    onClickApprove(item) {
      Swal.fire({
        icon: 'info',
        title: '連携の承認',
        text: `「${item[item.coop_user].username}」との連携を承認しますか？`,
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateCoopState(item, 1);
        }
      });
    },
    onClickRefuse(item) {
      Swal.fire({
        icon: 'warning',
        title: '連携の拒否',
        text: `「${item[item.coop_user].username}」との連携を拒否しますか？`,
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateCoopState(item, 4);
        }
      });
    },
    onClickResend(item) {
      Swal.fire({
        icon: 'info',
        title: '招待の再送',
        text: `${item.farmers_user.email} に招待メールを再送信しますか？`,
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post('/api/resend_invitation/', { user_id: item.farmers_user.id })
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: '招待を再送しました',
                showConfirmButton: true,
                showCloseButton: true,
              });
            });
        }
      });
    },
    updateCoopState(item, status) {
      axios
        .put(`api/coop_users/${item.id}/`, {
          status,
        })
        .then(() => {
          this.reload();
        })
        .catch(() => {
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton: true,
            showCloseButton: false,
            timer: 3000,
          });
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.invitation_wrapper {
  display: flex;
  justify-content: flex-end;
}
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display: none;
}
.plantInfo.selected .v-card {
  background-color: #888800 !important;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
.inquiry {
  white-space: pre-line;
}
</style>
